  .FileDropZone {
    min-height: auto !important;
    background: var(--bg-primary-color);
    border: 2px dashed var(--border-primary-color-focus) !important;
    color: var(--primary-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 50%;
  }

.FileDropZone p {
    margin: 0;
    font-weight: bold;
    font-family: inherit;
    font-size: 1.6rem;
}

.FileDropZone svg {
  color: var(--primary-color);
}

/* This CSS hide all subtitle1 typographies
/* .MuiTypography-subtitle1 {
  visibility: hidden;
} */

.MuiDropzonePreviewList-removeButton  {
  top: 10px !important;
}

.MuiDropzonePreviewList-root {
  margin: 0 !important;
}

.drop-zone-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0;
}

@media screen and (max-width: 600px)  {
 .drop-zone-container {
    flex-direction: column;
  }

  .FileDropZone {
    max-width: 100%;
  }
}

