@import 'styles/_variables.css';
@import 'styles/_fonts.css';

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  min-width: var(--wrapperWidthMin);
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: 'Roboto','OpenSans', Arial, Helvetica, sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  html {
    font-size: 56%;
  }

  body {
    overflow-y: scroll;
  }
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: all var(--transitionTime);
}

a:hover,
a.active {
  color: var(--primary-color-dark);
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
  margin: 0 0 .5rem 0;
}

h1 {
  font-size: 3.6rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.8rem;
}
h4 {
  font-size: 2.4rem;
}
h5 {
  font-size: 2.2rem;
}
h6 {
  font-size: 1.6rem;
}

small {
  font-size: 80%;
}

/* UTILS */
.mr1 {
  margin-right: 0.8rem;
}

.ml1 {
  margin-left: 0.8rem;
}

.warning {
  color: var(--warning-color);
  font-weight: 600 !important;
}

/* MATERIAL UI */

/* TOGGLE BUTTONS */
body .MuiToggleButton-root {
  min-width: 90px;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

body .MuiToggleButton-root.Mui-selected {
  color: var(--white);
  background-color: var(--primary-color);
}

body .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--primary-color);
}

body .MuiToggleButton-root svg,
body .MuiDialogTitle-root svg {
  margin-right: 1rem;
}

/* MODALS */
body .MuiDialog-paper {
  min-width: var(--wrapperWidthMin);
}

body .MuiDialog-paper form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

body .MuiDialogTitle-root {
  color: var(--white);
  background: var(--primary-color-dark);
}

  body .MuiDialogTitle-root.errorModal {
    background: var(--danger-color);
  }

  body .MuiDialogTitle-root.warningModal {
    background: var(--warning-color);
  }

  body .MuiDialogTitle-root.confirmModal {
    background: var(--success-color);
  }

body .MuiDialogContent-root {
  padding-top: 16px;
}

/* TOOLTIPS */
body .MuiTooltip-tooltip {
  font-size: 1.2rem;
}

/* SKELETONS */
body .MuiSkeleton-withChildren > * {
  visibility: visible;
}

body .MuiSkeleton-fitContent {
  max-width: initial;
}

/* fullscreen quizzes */
.fullscreen-enabled {
  background-color: white;
  overflow-y: auto;
}
