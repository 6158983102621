:root {
  /* COLORS */
  --primary-color: #017db8;
  --primary-color-dark: #02587E;
  --primary-color-light: #009AE7;

  --disabled-color: #D9D9D9;
  --disabled-color-dark: #737373;

  --success-color: #106e18;
  --warning-color: #D14900;
  --danger-color: #de1717;

  --colored-title-color: #1BA4E2;
  --colored-subtitle-color: #027CB1;

  --border-primary-color-focus: rgba(0, 154, 231, 0.5);
  --border-primary-color: rgba(0, 154, 231, 0.3);

  --bg-primary-color: #F2FAFE;
  --bg-primary-color-hover: #CCEBFA;

  --blue-light: #dde8f6;
  --yellow: #FFC000;

  --grey-darker: #404040;
  --grey-dark: #737373;
  --grey: #a6a6a6;
  --grey-light: #d9d9d9;
  --grey-lighter: #ececec;

  --black: #000000;
  --white: #ffffff;

  --grey-gradient: linear-gradient(75deg, rgba(85, 85, 85, 0.8) 20%,rgba(166, 166, 166, 0.1) 80%);
  --grey-gradient-card: linear-gradient(0deg, rgba(85, 85, 85, 0.6) 10%,rgba(166, 166, 166, 0.1) 60%);

  --header-grey: #555555;
  --header-blue: #0085B1;

  /* RESPONSIVE WIDTHS */
  --wrapperWidthMax: 1200px;
  --wrapperWidthMin: 320px;

  --smallWrapperWidthMax: 1000px;
  --largeWrapperWidthMax: 1600px;

  --tabletWidth: 768px;

  /* MISC */
  --transitionTime: 0.3s;

  --header-nav-height: 6rem;
  --header-nav-max-height: 20rem;
  --header-edition-nav-height: 6rem;
  --header-with-edition-bar-height: 12rem;
  --moduleHeader-height: 12rem;
  --header-item-width: 200px;

  --showMore-height: 6rem;

  --zindexSmall: 10;
  --zindexMedium: 50;
  --zindexBig: 100;
}