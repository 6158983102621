@font-face {
  font-family: 'OpenSans';
  src: url('fonts/OpenSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}